module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://banjarnegara.ml","contentApiKey":"084442a0a767c1415e96c78024"},"production":{"apiUrl":"https://banjarnegara.ml","contentApiKey":"084442a0a767c1415e96c78024"}},"siteConfig":{"siteUrl":"https://www.ozip.cf","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Just Do It","siteDescriptionMeta":"If you can do tomorrow, why should now. Why should you here, if another site is more useful.","shortTitle":"ozip.cf","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
